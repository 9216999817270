<template>
  <div class='cert-box'>
    <div class="cert-box__shape-bg" />
    <div class="cert-box__content-wrapper">
      <div class="cert-box__top-section">
        <img :src="associationImgSrc" >
        <span>{{ $t(associationLabelText) }}</span>
      </div>
      <div class="cert-box__bottom-section">
        <img :src="certifiedImgSrc" >
        <span>{{ $t(certifiedLabelText) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CertBox',
  props: {
    associationImgSrc: {
      type: String,
      required: true,
    },
    certifiedImgSrc: {
      type: String,
      required: true,
    },
    associationLabelText: {
      type: String,
      required: false,
      default: 'sections.about.certificate.certificateBox.labels.association',
    },
    certifiedLabelText: {
      type: String,
      required: false,
      default: 'sections.about.certificate.certificateBox.labels.certified',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.cert-box {
  width: 156px;
  height: 190px;
  color: $white;
  position: relative;

  img {
    max-height: 54px;
  }
}

.cert-box__shape-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 156px;
  height: 100px;
  background-color: $purple-light;
  margin-top: 45px;
  border-bottom: 35px solid $purple-middle;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 78px 45px 78px;
    border-color: transparent transparent $purple-light transparent;
    position: absolute;
    top: -45px;
    left: 0;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 45px 78px 0 78px;
    border-color: $purple-middle transparent transparent transparent;
    position: absolute;
    bottom: -80px;
    left: 0;
  }
}

.cert-box__content-wrapper {
  position: relative;
  z-index: 1;
  height: 100%;
}

.cert-box__top-section,
.cert-box__bottom-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    max-height: 54px;
  }

  span {
    display: block;
    width: 100%;
    font-family: 'Montserrat-Medium';
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
  }
}

.cert-box__top-section {
  height: 110px;
  padding: 22px 0 0 0;
  align-content: flex-start;

  span {
    margin: 7px 0 0 0;
  }
}

.cert-box__bottom-section {
  height: calc(190px - 110px);
  padding: 0 0 15px;
  align-content: center;

  span {
    margin: 4px 0 0 0;
  }
}
</style>
