<template>
  <div id='certificate-section'>
    <div class="syscops__container">
      <img
        src="/images/section-illustrations/mobile/references_illustration.svg"
        class="section-illustration">
      <h1 class="title">{{  $t('sections.about.certificate.title') }}</h1>
      <carousel class="content__carousel" :items="1" :startPosition="0"
        :nav="false" @changed="handleCarouselChange" :margin="0"
        :center="true" :responsive="owlCarouselConfig">
          <!-- <div v-for="(item, i) in certificates" :key="i" class="carousel__item"> -->
            <cert-box
              v-for="(links, index) in certificates" :key="index"
              :associationImgSrc="links.associationImg"
              :certifiedImgSrc="links.certifiedImg"
            />
          <!-- </div> -->
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import CertBox from '@/components/shared/CertBox.vue';

export default {
  name: 'CertificateSection',
  props: {},
  components: {
    carousel,
    CertBox,
  },
  data: () => ({
    carouselDir: 0,
    carouselCurrentPage: 0,
    owlCarouselConfig: {
      0: {
        items: 1,
        startPosition: 0,
        center: true,
        margin: 0,
        stagePadding: 60,
      },
      375: {
        items: 1,
        startPosition: 0,
        center: false,
        margin: 0,
        stagePadding: 10,
      },
    },
  }),
  created() {},
  computed: {
    certificates() {
      return [
        {
          associationImg: '/images/logos/terraform.png',
          certifiedImg: '/images/logos/cisco.png',
        },
        {
          associationImg: '/images/logos/terraform.png',
          certifiedImg: '/images/logos/siemens.png',
        },
        {
          associationImg: '/images/logos/terraform.png',
          certifiedImg: '/images/logos/cisco.png',
        },
        {
          associationImg: '/images/logos/terraform.png',
          certifiedImg: '/images/logos/siemens.png',
        },
        {
          associationImg: '/images/logos/terraform.png',
          certifiedImg: '/images/logos/cisco.png',
        },
        {
          associationImg: '/images/logos/terraform.png',
          certifiedImg: '/images/logos/siemens.png',
        },
      ];
    },
  },
  methods: {
    handleCarouselChange(data) {
      this.carouselDir = this.carouselCurrentPage < data.page.index ? 0 : 1;
      this.carouselCurrentPage = data.page.index;
    },
    getAnimationClass(pageIndex, itemIndex) {
      let result = '';
      if (pageIndex === this.carouselCurrentPage) {
        if (this.carouselDir) {
          if (itemIndex) {
            result = 'animate__animated animate__bounceInLeft slower';
          } else {
            result = 'animate__animated animate__bounceInLeft';
          }
        } else if (itemIndex) {
          result = 'animate__animated animate__bounceInRight slower';
        } else {
          result = 'animate__animated animate__bounceInRight';
        }
      }
      return result;
    },
  },
};
</script>

<style lang='scss' scoped>
#certificate-section {
  padding: 0 0 100px;
}

.section-illustration {
  margin: 0 auto 10px;
  display: block;
}

.title {
  @include sectionTitleStyle($margin: 0 0 30px);
  text-align: center;

  @media screen and (min-width: 576px) {
    font-size: 40px;
    line-height: 40px;
  }
}

.content__carousel {
  width: calc(100% + 40px);
  margin-left: -20px;
}

.cert-box {
  margin: 0 auto;
}

/deep/ .owl-carousel .owl-stage {
  padding-left: 10px !important;
}

/deep/ .owl-theme .owl-nav.disabled + .owl-dots {
  margin: 45px 0 0;

  .owl-dot {
    &:focus,
    &:active {
      outline: none;
    }

    span {
      margin: 0 7px;
      background: $purple-middle;
    }

    &.active span {
      background: $white;
    }
  }
}

/deep/ .owl-carousel .owl-item img {
  width: auto;
  height: auto;
  min-height: 15px;
  max-height: 55px;
  margin: 0 auto;

  &:nth-child(2) {
    margin: 45px auto;
  }
}
</style>
