var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"contact-section"}},[_c('div',{staticClass:"syscops__container"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('sections.identity.title')))]),_vm._m(0),_c('div',{staticClass:"content__form"},[_c('form',{ref:"emailForm",staticClass:"form__fields",on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail($event)}}},[_c('base-input',{ref:"nameInput",attrs:{"name":'name',"hasError":_vm.formInputs.name.hasError,"labelText":_vm.$t('sections.identity.form.labels.name')},on:{"updated":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleFormInput.apply(void 0, [ 'name' ].concat( argsArray ))}}}),_c('base-input',{ref:"emailInput",attrs:{"name":'email',"type":'email',"hasError":_vm.formInputs.email.hasError,"labelText":_vm.$t('sections.identity.form.labels.email')},on:{"updated":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleFormInput.apply(void 0, [ 'email' ].concat( argsArray ))}}}),_c('base-input',{ref:"messageInput",attrs:{"name":'message',"hasError":_vm.formInputs.message.hasError,"labelText":_vm.$t('sections.identity.form.labels.message')},on:{"updated":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.handleFormInput.apply(void 0, [ 'message' ].concat( argsArray ))}}}),_c('span',{staticClass:"form__info",domProps:{"innerHTML":_vm._s(_vm.$t('general.mobile.identity.form'))}}),_c('base-button',{attrs:{"title":_vm.$t('sections.identity.form.button.title'),"type":'submit',"isLoading":_vm.formButtonIsLoading}})],1)]),_c('go-to-top',{on:{"clicked":_vm.handleGoToTopClick}}),_c('div',{staticClass:"copyright-row"},[_c('social-links',{attrs:{"config":_vm.socialsConfig}}),_c('span',{staticClass:"copyright"},[_vm._v(_vm._s(_vm.$t('copyright.text')))])],1)],1),_c('img',{staticClass:"section-illustration",attrs:{"src":"/images/section-illustrations/mobile/welcome_illustration.svg"}}),_c('span',{staticClass:"verify-id"},[_vm._v(_vm._s(_vm.verifyId))])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contacts_container"},[_c('h3',[_vm._v("info@syscops.com")]),_c('h3',[_vm._v("+36 20 264 9536")])])}]

export { render, staticRenderFns }