<template>
  <div id='landing-screen-mobile'>
    <welcome-section />

    <get-to-know-section />

    <personalize-section />

    <create-infrastructure-section />

    <test-monitor-section />

    <services-section :isAnimate="isServicesAnimate" />

    <certificate-section />

    <references-section />

    <contact-section />

    <base-button
      class="global-button"
      :class="{ 'hidden': isGlobalButtonHidden }"
      :title="$t('general.mobile.button.text')"
      @clicked="handleBuildNowBtnClick"
    />
  </div>
</template>

<script>
import WelcomeSection from '@/screens/sections/mobile/WelcomeSection.vue';
import GetToKnowSection from '@/screens/sections/mobile/GetToKnowSection.vue';
import PersonalizeSection from '@/screens/sections/mobile/PersonalizeSection.vue';
import CreateInfrastructureSection from '@/screens/sections/mobile/CreateInfrastructureSection.vue';
import TestMonitorSection from '@/screens/sections/mobile/TestMonitorSection.vue';
import ServicesSection from '@/screens/sections/mobile/ServicesSection.vue';
import ReferencesSection from '@/screens/sections/mobile/ReferencesSection.vue';
import ContactSection from '@/screens/sections/mobile/ContactSection.vue';
import CertificateSection from '@/screens/sections/mobile/CertificateSection.vue';

import BaseButton from '@/components/shared/BaseButton.vue';

export default {
  name: 'LandingScreenMobile',
  props: {},
  components: {
    WelcomeSection,
    GetToKnowSection,
    PersonalizeSection,
    CreateInfrastructureSection,
    TestMonitorSection,
    ServicesSection,
    BaseButton,
    ReferencesSection,
    ContactSection,
    CertificateSection,
  },
  data: () => ({
    isGlobalButtonHidden: false,
    isServicesAnimate: false,
  }),
  created() {
    document.addEventListener('scroll', this.handleScroll);
  },
  computed: {},
  methods: {
    handleScroll() {
      const contactSection = document.getElementById('contact-section').getBoundingClientRect();

      const servicesSection = document.getElementById('services-section').getBoundingClientRect();

      this.isGlobalButtonHidden = contactSection.bottom - 750
        <= (window.innerHeight || document.documentElement.clientHeight);

      this.isServicesAnimate = servicesSection.bottom - 290
      <= (window.innerHeight || document.documentElement.clientHeight);
    },
    handleBuildNowBtnClick() {
      this.$SmoothScroll(document.getElementById('contact-section'));
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang='scss' scoped>
#landing-screen-mobile {}

.global-button {
  position: fixed;
  bottom: 20px;
  left: 30px;
  z-index: 100;
  width: calc(100% - 60px) !important;
  height: 60px !important;
  transition: $global-transition;

  &.hovered {
    transform: scale(1, 1.15);
  }

  &.hidden {
    opacity: 0;
  }
}
</style>
