<template>
  <div id='go-to-top' @click="handleClick">
    <span class="icon__triangle" />
    <span class="icon__circle" />
  </div>
</template>

<script>
export default {
  name: 'GoToTop',
  props: {},
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleClick() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang='scss' scoped>
#go-to-top {
  cursor: pointer;
}

.icon__triangle,
.icon__circle {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.icon__triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 16px 7px;
  border-color: transparent transparent $purple-icon transparent;
}

.icon__circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: $purple-icon;
  margin-top: 10px;
}
</style>
