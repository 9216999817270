<template>
  <div id='contact-section'>
    <div class="syscops__container">
      <h1 class="title">{{ $t('sections.identity.title') }}</h1>
      <div class="contacts_container">
        <h3>info@syscops.com</h3>
        <h3>+36 20 264 9536</h3>
      </div>
      <div class="content__form">
        <form class="form__fields" ref="emailForm" @submit.prevent="sendEmail">
          <base-input
            :name="'name'"
            ref="nameInput"
            :hasError="formInputs.name.hasError"
            :labelText="$t('sections.identity.form.labels.name')"
            @updated="handleFormInput('name', ...arguments)"
          />
          <base-input
            :name="'email'"
            ref="emailInput"
            :type="'email'"
            :hasError="formInputs.email.hasError"
            :labelText="$t('sections.identity.form.labels.email')"
            @updated="handleFormInput('email', ...arguments)"
          />
          <base-input
            :name="'message'"
            ref="messageInput"
            :hasError="formInputs.message.hasError"
            :labelText="$t('sections.identity.form.labels.message')"
            @updated="handleFormInput('message', ...arguments)"
          />
          <span class="form__info" v-html="$t('general.mobile.identity.form')" />
          <base-button
            :title="$t('sections.identity.form.button.title')"
            :type="'submit'"
            :isLoading="formButtonIsLoading"
          />
        </form>
      </div>

      <go-to-top @clicked="handleGoToTopClick" />
      <div class="copyright-row">
        <social-links :config="socialsConfig" />
        <span class="copyright">{{ $t('copyright.text') }}</span>
      </div>
    </div>
    <img src="/images/section-illustrations/mobile/welcome_illustration.svg"
      class="section-illustration">
    <span class="verify-id">{{ verifyId }}</span>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import BaseInput from '@/components/shared/elements/BaseInput.vue';
import BaseButton from '@/components/shared/BaseButton.vue';
import SocialLinks from '@/components/shared/SocialLinks.vue';

import GoToTop from '@/components/shared/icons/GoToTop.vue';

export default {
  name: 'ContactSection',
  props: {},
  components: {
    BaseInput,
    BaseButton,
    GoToTop,
    SocialLinks,
  },
  data: () => ({
    socialsConfig: [
      {
        iconUrl: '/images/icons/github.svg',
        link: 'https://github.com',
      },
      {
        iconUrl: '/images/icons/fb.svg',
        link: 'https://facebook.com',
      },
      {
        iconUrl: '/images/icons/twitter.svg',
        link: 'https://twitter.com',
      },
      {
        iconUrl: '/images/icons/instagram.svg',
        link: 'https://instagram.com',
      },
    ],
    formInputs: {
      name: {
        value: undefined,
        hasError: false,
      },
      email: {
        value: undefined,
        hasError: false,
      },
      message: {
        value: undefined,
        hasError: false,
      }
    },
    formButtonIsLoading: false,
  }),
  created() {},
  computed: {
    verifyId() {
      return `0x${this.generateRandomHash(40)}`;
    },
  },
  methods: {
    generateRandomHash(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    handleGoToTopClick() {
      this.$SmoothScroll(0);
    },
    handleFormInput(fieldName, value) {
      switch (fieldName) {
        case 'name':
          this.formInputs.name.value = value;
          break;
        case 'email':
          this.formInputs.email.value = value;
          break;
        case 'message':
          this.formInputs.message.value = value;
          break;
        default:
          break;
      }
    },
    validateForm() {
      // name validation
      this.formInputs.name.hasError = this.formInputs.name.value === undefined
        || this.formInputs.name.value.length === 0
        || this.formInputs.name.value.length < 5;

      // email validation
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.formInputs.email.hasError = this.formInputs.email.value === undefined
        || this.formInputs.email.value.length === 0
        || !re.test(this.formInputs.email.value);

      return this.formInputs.name.hasError && this.formInputs.email.hasError;
    },
    sendEmail(e) {
      const validateResult = this.validateForm();

      if (!validateResult) {
        emailjs.sendForm(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
          e.target,
          process.env.VUE_APP_EMAILJS_USER_ID,
        )
          .then(() => {
            this.$eventBus.$emit('triggerNotification', this.$t('sections.identity.form.notification.success'));
            this.formInputs.name.value = undefined;
            this.formInputs.email.value = undefined;
            this.formInputs.message.value = undefined;
            this.$refs.nameInput.$refs.input.value = '';
            this.$refs.emailInput.$refs.input.value = '';
            this.$refs.messageInput.$refs.input.value = '';
          }, (error) => {
            console.log('FAILED...', error);
          });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
#contact-section {
  position: relative;
  overflow: hidden;
  padding: 55px 0 25px;
}

.title {
  @include sectionTitleStyle($margin: 0 0 20px);
  text-align: left;
  position: relative;
  z-index: 1;
}

.form__fields {
  position: relative;
  z-index: 1;

  .base-input {
    margin: 0 0 10px;
  }
}

.form__info {
  font-size: 8px;
  line-height: 14px;
  display: inline-block;
  font-family: 'Montserrat-Regular';
  color: $global-secondary-font-color;
  margin: 12px 0 0;

  /deep/ a {
    text-decoration: none;
    font-family: 'Montserrat-Bold';
    color: $global-secondary-font-color;
  }
}

.section-illustration {
  position: absolute;
  top: -5px;
  right: -10px;
  height: 270px;
  z-index: 0;
}

.verify-id {
  position: absolute;
  top: 0;
  right: 15px;
  transform: rotate(-90deg);
  color: rgba($global-secondary-font-color, 0.25);
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  display: inline-block;
  transform-origin: right;
  text-align: center;
  margin: 0;
  width: 514px;
  height: 22px;
}

.base-button {
  margin: 15px 0 0;
  width: 100% !important;
  height: 60px !important;
}

#go-to-top {
  margin: 60px 0 0;
}

.copyright-row {
  margin: 85px 0 0;

  .copyright {
    margin: 55px 0 0;
    font-size: 11px;
    color: $global-secondary-font-color;
    line-height: 16px;
    font-family: 'Montserrat-Regular';
    text-align: center;
    display: block;
    padding: 0 20px;
  }
}
</style>
