<template>
  <div id='services-section'>
    <div class="syscops__container">

      <ul class="services-list" :style="`--currentElementIndex: ${animationStep}`">
        <div class="services-list__items-wrapper">
           <li v-for="(item, i) in services" :key="i"
             :class="['services-list__item',
              { 'services-list__item--active': i === animationStep }]">
              {{ item }}
            </li>
        </div>
      </ul>

      <div class="illustrations-list">
        <div v-for="n in 9" :key="n" :class="['animation-helper-wrapper',
          { 'use': n-1 === 7 || n-1 === 8 },
          { 'visible': n-1 <= animationStep }]">
          <img :src="`/images/section-illustrations/services/${n-1}.png`"
            :class="['illustration', `illustration-${n-1}`,
              { 'visible': n-1 <= animationStep },
              { 'hide': animationStep >= 3 && n-1 == 1 }]">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesSection',
  props: {
    isAnimate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  data: () => ({
    animationStep: 0,
    animationTimeOut: undefined,
  }),
  created() {},
  computed: {
    services() {
      return [
        this.$t('sections.services.cloudProvider'),
        this.$t('sections.services.networking'),
        this.$t('sections.services.servers'),
        this.$t('sections.services.firewall'),
        this.$t('sections.services.security'),
        this.$t('sections.services.monitoring'),
        this.$t('sections.services.backup'),
        this.$t('sections.services.deploy'),
        this.$t('sections.services.live'),
      ];
    },
  },
  watch: {
    isAnimate(val) {
      if (val) {
        this.animation(this.animationStep);
      } else {
        clearTimeout(this.animationTimeOut);
      }
    },
    animationStep(value) {
      if (value === this.services.length - 1) {
        setTimeout(() => {
          this.animationStep = 0;
          this.animation(this.animationStep);
        }, 10000);
      }
    },
  },
  methods: {
    animation(n) {
      if (n + 1 < this.services.length) {
        this.animationTimeOut = setTimeout(() => {
          this.animation(this.animationStep += 1);
        }, 2000);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
#services-section {
  padding: 0 0 100px;

  @media screen and (min-width: 576px) {
    overflow: hidden;
  }
}

.services-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 50px;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 0;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.8s;

  @media screen and (min-width: 576px) {
    height: 70px;
  }
}

.services-list__items-wrapper {
  transition: all 0.8s ease-in-out;
  transform: translateY(calc(-1 * (var(--currentElementIndex) * 50px)));

  @media screen and (min-width: 576px) {
    transform: translateY(calc(-1 * (var(--currentElementIndex) * 60px)));
  }
}

.services-list__item {
  font-family: 'AkzidenzGrotesk-Bold';
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  height: 50px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.8s ease-out;

  @media screen and (min-width: 576px) {
    font-size: 35px;
    line-height: 35px;
    height: 60px;
  }

  &--active {
    opacity: 1;
  }
}

.animation-helper-wrapper {
  &.use {
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.visible {
      opacity: 1;
    }
  }
}

.illustrations-list {
  width: 100%;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  margin: 40px 0 0;

  @media screen and (max-width: 320px) {
    left: -10px;
    transform: none;
  }

  @media screen and (min-width: 576px) {
    height: 250px;
    left: -20px;
    transform: scale(1.5);
    margin: 100px 0 0;
  }

  .illustration {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: auto;
    width: 100%;
    top: 50px;
    transition: all 0.5s ease-in-out;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (min-width: 576px) {
      top: 20px;
    }

    &.hide {
      opacity: 0 !important;
    }

    &.visible {
      top: 0;
      opacity: 1;
    }

    &-0,
    &-2,
    &-3,
    &-4 {
      width: 243px;
      height: 150px;
    }

    &-1,
    &-3 {
      width: 266px;
      height: 163px;
      margin-left: 19px;

      &.visible {
        top: 4px;
      }
    }

    &-4 {
      z-index: 1;
    }

    &-5 {
      width: 257px;
      height: 148px;
      z-index: 2;
      margin-left: 12px;

      &.visible {
        top: 21px;
      }
    }

    &-6 {
      width: 262px;
      height: 197px;

      &.visible {
        top: -16px;
      }
    }

    @keyframes linesAnimation {
      0% {
        top: 0px;
        opacity: 0.2;
      }
      50% {
        top: 10px;
        opacity: 1;
      }
      100% {
        top: 0px;
        opacity: 0.2;
      }
    }

    &-7 {
      width: auto;
      height: 150px;
      margin-left: 5px;
      animation: linesAnimation 2.5s
        cubic-bezier(0.71, 0.01, 0.37, 1.01) infinite;

      &.visible {
        top: 30px;
        z-index: 2;
      }
    }

    @keyframes routeAnimation {
      0% {
        top: -13px;
        opacity: 0.2;
      }
      50% {
        top: -18px;
        opacity: 1;
      }
      100% {
        top: -13px;
        opacity: 0.2;
      }
    }

    &-8 {
      width: 292px;
      height: 195px;
      margin-left: 19px;
      animation: routeAnimation 2.5s
        cubic-bezier(0.71, 0.01, 0.37, 1.01) infinite;

      &.visible {
        z-index: -1;
        top: -13px;
      }
    }

  }
}
</style>
