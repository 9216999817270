<template>
  <div id='references-section'>
    <div class="syscops__container">
      <img
        src="/images/section-illustrations/mobile/references_illustration.svg"
        class="section-illustration">
      <h1 class="title">{{ $t('sections.about.references.title') }}</h1>
      <carousel class="content__carousel" :items="1" :startPosition="1"
        :nav="false" @changed="handleCarouselChange">
        <div v-for="(item, i) in refrenceLogos" :key="i" class="carousel__item">
          <img v-for="(link, index) in item" :key="index"
            :src="link"
            :class="getAnimationClass(i, index)">
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';

export default {
  name: 'ReferencesSection',
  props: {},
  components: {
    carousel,
  },
  data: () => ({
    carouselDir: 0,
    carouselCurrentPage: 0,
  }),
  created() {},
  computed: {
    refrenceLogos() {
      return [
        [
          '/images/logos/apple.png',
          '/images/logos/sony.png',
          '/images/logos/logmein.png',
        ],
        [
          '/images/logos/apple.png',
          '/images/logos/sony.png',
          '/images/logos/logmein.png',
        ],
        [
          '/images/logos/apple.png',
          '/images/logos/sony.png',
          '/images/logos/logmein.png',
        ],
      ];
    },
  },
  methods: {
    handleCarouselChange(data) {
      this.carouselDir = this.carouselCurrentPage < data.page.index ? 0 : 1;
      this.carouselCurrentPage = data.page.index;
    },
    getAnimationClass(pageIndex, itemIndex) {
      let result = '';
      if (pageIndex === this.carouselCurrentPage) {
        if (this.carouselDir) {
          if (itemIndex) {
            result = 'animate__animated animate__bounceInLeft slower';
          } else {
            result = 'animate__animated animate__bounceInLeft';
          }
        } else if (itemIndex) {
          result = 'animate__animated animate__bounceInRight slower';
        } else {
          result = 'animate__animated animate__bounceInRight';
        }
      }
      return result;
    },
  },
};
</script>

<style lang='scss' scoped>
#references-section {
  padding: 0 0 100px;
}

.section-illustration {
  margin: 0 auto 10px;
  display: block;
}

.title {
  @include sectionTitleStyle($margin: 0 0 30px);
  text-align: center;

  @media screen and (min-width: 576px) {
    font-size: 40px;
    line-height: 40px;
  }
}

.content__carousel {
  width: calc(100%  + 40px);
  margin-left: -20px;
  margin-right: -20px;
}

.carousel__item {
  .animate__animated.slower {
    animation-delay: 0.1s !important;
  }
}

/deep/ .owl-theme .owl-nav.disabled + .owl-dots {
  margin: 45px 0 0;

  .owl-dot {
    &:focus,
    &:active {
      outline: none;
    }

    span {
      margin: 0 7px;
      background: $purple-middle;
    }

    &.active span {
      background: $white;
    }
  }
}

/deep/ .owl-carousel {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  .owl-item {
    height: 100%;
  }
}

.carousel__item {
  height: 100%;
  display: grid;
  align-content: space-between;
}

/deep/ .owl-carousel .owl-item img {
  width: auto;
  height: auto;
  min-height: 25px;
  max-height: 40px;
  margin: 0 auto;

  &:nth-child(2) {
    margin: 45px auto;
  }
}
</style>
