<template>
  <div id='test-monitor-section'>
    <div class="syscops__container">
      <img src="/images/section-illustrations/mobile/test-monitor_illustration.svg"
        class="section-illustration">
      <h1 class="title">{{ $t('sections.testAndMonitor.title') }}</h1>
      <p class="description">{{ $t('sections.testAndMonitor.description') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestMonitorSection',
  props: {},
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
#test-monitor-section {
  padding: 0 0 100px;
}

.section-illustration {
  width: 100%;
  height: auto;

  @media screen and (min-width: 576px) {
    max-width: 450px;
    display: block;
    margin: 0 0 20px auto;
  }
}

.title {
  @include sectionTitleStyle($margin: 0 0 12px);

  @media screen and (min-width: 576px) {
    font-size: 40px;
    line-height: 40px;
    text-align: right;
    max-width: none;
  }
}

.description {
  @include sectionDescriptionStyle($margin: 0);

  @media screen and (min-width: 576px) {
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    margin-left: auto;
  }
}
</style>
