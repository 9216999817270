<template>
  <div id='welcome-section'>
    <div class="syscops__container">
      <div class="title__wrapper">
        <h1
          class="title__text-bordered"
          v-html="$t('sections.welcome.title')" />
        <h1
          class="title__text"
          v-html="$t('sections.welcome.title')"
        />
      </div>
      <div
          class="description"
          v-html="$t('sections.welcome.description')"
        />
    </div>
    <img class="section-illustration"
      src="/images/section-illustrations/mobile/welcome_illustration.svg">
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
#welcome-section {
  position: relative;
  overflow: hidden;
  padding: 0 0 100px;

  @media screen and (min-width: 576px) {
    min-height: 450px;
  }
}

.title__wrapper {
  position: relative;
  top: 0;
  transition: all 1s ease-in-out;
}

.title__text {
  font-size: 30px;
  letter-spacing: 0;
  line-height: 1;
  margin: 40px 0 20px;
  font-family: 'AkzidenzGrotesk-Bold';
  position: relative;
  white-space: pre-wrap;

  @media screen and (min-width: 576px) {
    font-size: 40px;
  }

  &-bordered {
    font-size: 30px;
    letter-spacing: 0;
    line-height: 1;
    margin: 0;
    font-family: 'AkzidenzGrotesk-Bold';
    color: rgba(0, 0, 0, 0.0);
    text-shadow: -1px 0 $white, 0 1px $white, 1px 0 $white, 0 -1px $white;
    position: absolute;
    top: 2px;
    left: 0;
    z-index: 2;

    @media screen and (min-width: 576px) {
      font-size: 40px;
    }

    .down & {
      animation: bordered-title-scroll 1.5s;
    }

    .up & {
      animation: bordered-title-scroll-2 1.5s;
    }

    @supports((text-stroke: 1px $white) or (-webkit-text-stroke: 1px $white)) {
      color: transparent;
      -webkit-text-stroke: 1px $white;
      text-stroke: 1x $white;
      text-shadow: none;
    }
  }
}

.description {
  max-width: 260px;

  @media screen and (min-width: 576px) {
    max-width: 70%;
    position: relative;
    z-index: 1;
  }

  /deep/ p {
    font-family: 'Montserrat-Regular';
    line-height: 18px;
    font-size: 12px;
    margin: 0;
    display: inline-block;

    @media screen and (min-width: 576px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.section-illustration {
  position: absolute;
  top: 4px;
  right: -10px;
  height: 270px;

  @media screen and (min-width: 576px) {
    width: 650px;
    height: auto;
  }
}
</style>
